.daterangepicker {
	&.hide-input {
		.daterangepicker_input {
			display: none !important;
		}
	}
}

@media only screen and (max-width : 400px) {
	.daterangepicker {
		width: 96vw;

		.calendar {
			max-width: none;

			&.right {
				display: none !important;
			}
	 	}
	}
 }


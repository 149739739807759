.mpi-main {
    display: flex;
    
    .mpi-add-btn {
        cursor: pointer;
        font-size: 50px;
    }

    .mpi-item {
        margin-bottom: 10px;
        
        > img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 11vh;
        }
    }

    .mpi-remove-btn {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: bold;
        padding: 2px;
        text-align: center;
    }

    &.mpi-primary .mpi-remove-btn {
        background-color: #3c8dbc;
        color: #fff;
    }

    &.mpi-primary .mpi-remove-btn:hover {
        background-color: #367fa9;  
    }

    &.mpi-primary .mpi-remove-btn:active {
        background-color: #204d74;
    }

    &.mpi-danger .mpi-remove-btn {
        background-color: #bf493a;;
        color: #fff;
    }

    &.mpi-danger .mpi-remove-btn:hover {
        background-color: #a53f32;  
    }

    &.mpi-danger .mpi-remove-btn:active {
        background-color: #823026;
    }
}
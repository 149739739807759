.user-details-box {
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;

	> :not(ul) {
		margin-right: 15px;
	}
}


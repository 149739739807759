.wizard-steps {
	display: flex;

	li {
		margin: 2px;
		padding: 8px 0 !important;
		text-align: center;
		
		&.active {
		    margin: -2px 0 !important;
			padding: 12px 2px !important;
		}

		> a {
			.fa	{
				position: initial;
				font-size: 1.3em;
				width: 100%;
				margin: 0;	
			}
		} 


		a:after {
			content: "" !important;
		}
	}
}
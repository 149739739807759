.worker-time-sheet-container {
    input {
        &::-webkit-clear-button, 
        &::-webkit-calendar-picker-indicator, 
        &::-webkit-inner-spin-button 
        { 
            display: none; /* Hide the button */ 
            -webkit-appearance: none; /* turn off default browser styling */ 
        } 
 
        &::-ms-clear { } 
    }

    .column-title {
        color: #ffffff;
        background: #909090;
        text-align: center !important;
        border-right: 2px solid #6f6f6f !important;
    }

    .split-line {
        border-right: 2px solid #bfbfbf !important;
    }

    .edit {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    //Table
    .rt-tbody {
        .rt-tr {
            .rt-td {
                white-space: unset;
            }
        }
    }
}
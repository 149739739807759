.pre-start-list-table {    
    .popover-content {
        overflow: auto;
        max-height: 200px;
    }

    .pending-approval {
        background: rgba(230, 147, 14, .07);
    }
    
    .not-approved {
        background: rgba(255, 0, 0, .07);
    }

    .run-in-progress {
        background: rgba(60, 141, 188, .07);
    }

    .last-part-sent {
        background: rgba(0, 0, 0, .07);
    }

    .finalized {
        background: rgba(0, 144, 1, .07);
    }
}
.noUi-horizontal {
	margin-top: 8px !important;
}

.noUi-margin {
	margin-bottom: 45px !important;
}

.noUi-handle {
	cursor: move;
	cursor: -webkit-grab !important; 
	cursor: -moz-grab !important; 
	 
}

.noUi-active {
	cursor: move; 
	cursor: -webkit-grabbing !important; 
	cursor: -moz-grabbing !important; 
	
}
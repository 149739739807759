$color-default: #eeeeee;
$color-primary: #337ab7;
$color-success: #5cb85c;
$color-info:    #5bc0de;
$color-warning: #f0ad4e;
$color-danger:  #d9534f;

.mvi-main {
    overflow: hidden;
    word-wrap: break-word;
    cursor: text;
    background-color: #fff;
    border: 1px solid #d2d6de;
    height: 100%;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    
    &.mvi-focused {
        border-color: #3c8dbc;
        outline: 0;
    }

    &.error {
        border-color: #ff0000;
    }

    input {
        border: 0;
        height: 32px;
        float: left;
        padding: 0 10px;

        &:read-only {
            cursor: text;
        }

        &::-webkit-calendar-picker-indicator {
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        * {
            display: inline-block;
            float: left;
        }

        li.mvi-item {
            margin: 2px 2px 0 2px;

            div {
                color: #ffffff;
                padding: 4px 10px;

                &.mvi-value {
                    border-bottom-left-radius: 6px;
                    border-top-left-radius: 6px;
                    margin-left: 3px;
                }

                &.mvi-remove {
                    border-bottom-right-radius: 6px;
                    border-top-right-radius: 6px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    padding-top: 2px;

                    &:after {
                        content: 'x';
                    }

                    // &:hover {
                    //     background-color: #2b6b98;
                    // }

                    // &:active {
                    //     background-color: #25608a;
                    // }
                }
            }

            /***************
            **** COLORS ****
            ***************/
            
            /* Default */
            &.mvi-default {
                .mvi-value {
                    color: #555555;
                    background-color: $color-default;
                }

                .mvi-remove {
                    color: #555555;
                    background-color: darken($color-default, 4%);

                    &:hover {
                        background-color: darken($color-default, 10%);
                    }

                    &:active {
                        background-color: darken($color-default, 15%);
                    }
                }
            }

            /* Primary */
             &.mvi-primary {
                .mvi-value {
                    background-color: $color-primary;
                }

                .mvi-remove {
                    background-color: darken($color-primary, 4%);

                    &:hover {
                        background-color: darken($color-primary, 10%);
                    }

                    &:active {
                        background-color: darken($color-primary, 15%);
                    }
                }
            }

            /* Info */
             &.mvi-info {
                .mvi-value {
                    background-color: $color-info;
                }

                .mvi-remove {
                    background-color: darken($color-info, 4%);

                    &:hover {
                        background-color: darken($color-info, 10%);
                    }

                    &:active {
                        background-color: darken($color-info, 15%);
                    }
                }
            }

            /* Warning */
             &.mvi-warning {
                .mvi-value {
                    background-color: $color-warning;
                }

                .mvi-remove {
                    background-color: darken($color-warning, 4%);

                    &:hover {
                        background-color: darken($color-warning, 10%);
                    }

                    &:active {
                        background-color: darken($color-warning, 15%);
                    }
                }
            }

            /* Danger */
             &.mvi-danger {
                .mvi-value {
                    background-color: $color-danger;
                }

                .mvi-remove {
                    background-color: darken($color-danger, 4%);

                    &:hover {
                        background-color: darken($color-danger, 10%);
                    }

                    &:active {
                        background-color: darken($color-danger, 15%);
                    }
                }
            }
        }
    }
}
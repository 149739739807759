.wizard-steps {
	padding: 0;

	li {
		background: #bdbdbd;
		cursor: pointer;
		list-style-type: none;
		margin-bottom: 2px;
		opacity: .8;
		padding: 20px 20px 20px 70px !important;
		width: 100%;

		> a {
			color: #565656;
			font-size: 1.8rem;
			font-weight: bold;

			.fa {
				position: absolute;
				left: 30px;
				margin-top: -7px;
				text-align: center;
				width: 40px;
			}

			&:after {
				font-family: 'FontAwesome';
				position: absolute;
			}
		}

		&.current {
			background-color: #5a9ac7;
		}

		&.completed {
			background-color: #66b166;

			&:not(.active) a:after {
				content: "\f00c";
				font-size: 20px;
				right: 28px;
			}
		}

		&.skipped {
			background-color: #bd843b;

			&:not(.active) a:after {
				content: "\f103";
				font-size: 20px;
				right: 28px;
			}
		}

		&.completed a,
		&.current a,
		&.invalid a,
		&.skipped a {
			color: rgba(255, 255, 255, .85);
		}

		&:hover {
			opacity: 1;
		}

		&.active {
			opacity: 1;

			a:after {
				content: "\f105";
				font-size: 35px;
				margin-top: -12px;
				right: 32px;
			}
		}

		&.invalid {
			background-color: #b54b4b;
		}
	}

	li:not(.completed, .current):hover {
		background-color: #d8d8d8;
	}
}

.wizard-content {
	display: none;

	&.active {
		display: block;
	}

	.box-body {
		overflow: auto;
	}
}

.wizard-buttons {
	padding: 0;
	text-align: right;

	li {
		display: inline-block;
		list-style-type: none;
		padding-right: 5px;
		padding-left: 5px;

		.btn {
			width: 100px; 
		}
	}
}

.wizard-inline {
	@import './wizardStepsInline';
}

@media (max-width: 991px) {
  	@import './wizardStepsInline';
  	.wizard-content {
		.box-body {
			max-height: 100%;
		}
	}
}